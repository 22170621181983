import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputEquipmentModel } from "../../../models/InputModels";
import SAPContract from "../../../components/bzr-service/sap-contract/sapContract.component";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Client from "../../../api-services/bzr-service/client";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";
import { style } from "../../../common/select-style/SelectStyle";

const CreateInputEquipment = ({
  withContract,
  addEquipment,
  equipmentData,
  isEdit,
  inputEquipment,
  HideDialog,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const [equipment, setEquipment] = useState(new InputEquipmentModel());
  equipment.bzrWithContract = withContract;
  const [searchEquipment, setSearchEquipment] = useState(
    new InputEquipmentModel()
  );
  const [edit, setEdit] = useState(false);
  const [sapError, setSapError] = useState(false);
  const statuses = [
    {
      value: 1,
      label: "Aktivan",
    },
    {
      value: 4,
      label: "Suspendovan",
    },
  ];

  const schema = {
    companyName: inputEquipment ? Joi.optional() : Joi.string().required(),
    name: Joi.string().required(),
    model: Joi.string().required(),
    type: Joi.string().required(),
    identificationNumber: Joi.string().required(),
  };

  var client = new Client();

  useEffect(() => {
    if (equipmentData) {
      setEquipment(equipmentData);
      setEdit(isEdit);
    }
  }, [equipmentData]);

  const handleChange = (contract) => {
    setEquipment({
      ...equipment,
      fkSapcontract: contract.id,
      companyName: contract.contractingParty,
    });
    setSapError(false);
    setErrors({
      ...errors,
      companyName: null,
    });
  };

  const handleEquipmentChange = (value) => {
    if (value) {
      var obj = value;
    } else {
      var obj = new InputEquipmentModel();
    }
    obj.fkSapcontract = equipment.fkSapcontract;
    obj.fkSapcontractNavigation = equipment.fkSapcontractNavigation;
    obj.companyName = equipment.companyName;
    setEquipment(obj);
    setSearchEquipment(obj);
  };

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, equipment, errors, schema, t);
    setEquipment(result.data);
    setErrors(result.errors);
  };

  const getCreatedEquipments = async (value) => {
    if (value.length > 2) {
      let data = {
        filter: {
          nameOrModelOrIdentificationNumber: value,
        },
        page: 1,
        pageSize: 10,
      };
      let response = await client.SearchEquipments(data, true);
      return response.results;
    }
  };

  const handleAdding = () => {
    const errorsResult = validateForm(equipment, schema, t);
    setErrors(errorsResult);

    let contractError =
      !inputEquipment && withContract && !equipment.fkSapcontract
        ? true
        : false;
    if (contractError) {
      setSapError(true);
    } else {
      setSapError(false);
    }

    if (!errorsResult && !contractError) {
      addEquipment(equipment);
      HideDialog && HideDialog();
    }
  };

  return (
    <>
      {!inputEquipment && (
        <>
          {withContract && (
            <>
              <SAPContract
                fkSapcontract={equipment.fkSapcontract}
                handleChange={handleChange}
                canChange={true}
                contractData={equipment.fkSapcontractNavigation}
              />
              {sapError && (
                <div className="text-danger">{t("RequiredField")}</div>
              )}
            </>
          )}
          <div className="form-group">
            <label>{t("Executor")}</label>
            <input
              type="text"
              className="form-control"
              value={equipment.companyName}
              onChange={(e) =>
                handlePropertyChange("companyName", e.target.value)
              }
            />
            {errors?.companyName && (
              <div className="text-danger">{errors.companyName}</div>
            )}
          </div>
          {isEdit && (
            <div className="form-group">
              <label>{t("Status")}</label>
              {equipment.status == 2 ? (
                <input
                  type="text"
                  className="form-control"
                  value={"Neaktivan"}
                  disabled
                />
              ) : (
                <Select
                  placeholder={t("SelectFromList")}
                  options={statuses}
                  onChange={(e) => {
                    setEquipment({ ...equipment, status: e.value });
                  }}
                  menuPortalTarget={document.body}
                  styles={style}
                  value={statuses.filter((x) => x.value == equipment.status)}
                />
              )}
            </div>
          )}
        </>
      )}

      {!isEdit && (
        <div className="form-group">
          <label>Dodavanje već kreirane opreme</label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            styles={style}
            value={searchEquipment}
            getOptionLabel={(e) =>
              e.id &&
              "(" + e.identificationNumber + ") " + e.name + " - " + e.model
            }
            getOptionValue={(e) => e.id}
            loadOptions={getCreatedEquipments}
            onChange={handleEquipmentChange}
            placeholder="Potrebno je uneti minimum 3 karaktera..."
            isClearable
          />
        </div>
      )}
      <div className="form-group">
        <label>{t("EquipmentName")}</label>
        <input
          type="text"
          className="form-control"
          value={equipment.name}
          onChange={(e) => handlePropertyChange("name", e.target.value)}
        />
        {errors?.name && <div className="text-danger">{errors.name}</div>}
      </div>
      <div className="form-group">
        <label>{t("Model")}</label>
        <input
          type="text"
          className="form-control"
          value={equipment.model}
          onChange={(e) => handlePropertyChange("model", e.target.value)}
        />
        {errors?.model && <div className="text-danger">{errors.model}</div>}
      </div>
      <div className="form-group">
        <label>{t("Type")}</label>
        <input
          type="text"
          className="form-control"
          value={equipment.type}
          onChange={(e) => handlePropertyChange("type", e.target.value)}
        />
        {errors?.type && <div className="text-danger">{errors.type}</div>}
      </div>
      <div className="form-group">
        <label>{t("IdentificationNumber")}</label>
        <input
          type="text"
          className="form-control"
          value={equipment.identificationNumber}
          onChange={(e) =>
            handlePropertyChange("identificationNumber", e.target.value)
          }
        />
        {errors?.identificationNumber && (
          <div className="text-danger">{errors.identificationNumber}</div>
        )}
      </div>
      <div className="clearfix">
        <button type="button" className="btn btn-accept" onClick={handleAdding}>
          {edit ? t("Change") : t("Add")}
        </button>
      </div>
    </>
  );
};

export default CreateInputEquipment;

import UserService from "../../utils/authentification/keycloak";

export const navigateToPage = (path, navigate, roles) => {
  if (path === "/BZRService") {
    const mainPageForApp = bzrPathBasedOnRole(roles);
    navigate(mainPageForApp);
  }
  if (path === "/Contracts") {
    const mainPageForApp = contractsPathBasedOnRole(roles);
    navigate(mainPageForApp);
  }
  if (path === "/ExternalExecutorLicences") {
    const mainPageForApp = eeLicencesPathBasedOnRole(roles);
    navigate(mainPageForApp);
  }
  if (path === "/EmployeeLicences") {
    const mainPageForApp = eLicencesPathBasedOnRole(roles);
    navigate(mainPageForApp);
  }
  if (path === "/AdminPanel") {
    const mainPageForApp = adminPanelPathBasedOnRole(roles);
    navigate(mainPageForApp);
  }
};

const bzrPathBasedOnRole = (roles) => {
  if (UserService.isLoggedIn()) {
    return "/BZRService";
  }
};

const contractsPathBasedOnRole = (roles) => {
  if (UserService.isLoggedIn()) {
    return "/Contracts";
  }
};

const eeLicencesPathBasedOnRole = (roles) => {
  if (UserService.isLoggedIn()) {
    return "/ExternalExecutorLicences";
  }
};

const eLicencesPathBasedOnRole = (roles) => {
  if (UserService.isLoggedIn()) {
    if (roles.indexOf("ApplicantOutOfWorkingTimeLicence") >= 0) return "/EmployeeLicences";
    else if (roles.indexOf("ApplicantEmployee") >= 0)
      return "/EmployeeLicences/MyLicences";
    else return "/EmployeeLicences";
  }
};

const adminPanelPathBasedOnRole = (roles) => {
  if (UserService.isLoggedIn()) {
    if (roles.indexOf("Administrator") >= 0) return "/AdminPanel";
    else if (roles.indexOf("AdministratorOfExternalExecutorAccounts") >= 0)
      return "/AdminPanel/ExternalUsers";
    else if (roles.indexOf("CompanyAdministrator") >= 0)
      return "/AdminPanel/Companies";
    else return "/AdminPanel";
  }
};

import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import { useTranslation } from "react-i18next";
import { formElements } from "../../../common/licence-form-elements/FormElements";
import { useEffect } from "react";

const componentMode = ["create", "preview", "renewal", "retroactive"];

const LicenceForm = ({
  model,
  setModel,
  mode,
  licenceType,
  errors,
  handleChange,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!componentMode.includes(mode)) {
      throw new Error("Invalid mode");
    }
  }, []);

  return (
    <div className="row">
      {formElements
        .filter(
          (x) => x.licenceType == "all" || x.licenceType.includes(licenceType)
        )
        .map((y, index) => {
          return (
            <div className="col-md-3" key={index}>
              <label>{t(y.elementName)}</label>
              {y.elementType == "input" && (
                <input
                  type="text"
                  className="form-control"
                  value={model && model[y.elementName]}
                  onChange={(e) => handleChange(y.elementName, e.target.value)}
                  disabled={y.disabled || mode === "preview"}
                />
              )}{" "}
              {y.elementType == "checkbox" && (
                <input
                  type="checkbox"
                  value={model && model[y.elementName]}
                  checked={model && model[y.elementName]}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      [y.elementName]: e.currentTarget.checked,
                    })
                  }
                  disabled={y.disabled || mode === "preview"}
                />
              )}
              {y.elementType == "date" && (
                <>
                  <br />
                  <CustomDatePicker
                    selected={model && model[y.elementName]}
                    onChange={(date) => handleChange(y.elementName, date)}
                    minDate={
                      mode != "retroactive" &&
                      y.elementName != "visitDate" &&
                      new Date()
                    }
                    disabled={
                      y.disabled || mode === "preview" || mode === "renewal"
                    }
                  />
                </>
              )}
              {errors && errors[y.elementName] && (
                <div className="text-danger">{errors[y.elementName]}</div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default LicenceForm;

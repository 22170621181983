import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../common/table/Table.component";
import SearchSapContract from "../../components/sap-contract/search-sap-contract/searchSapContract.component";
import { format } from "date-fns";
import Client from "../../api-services/sap-contract/client";
import { MainContainer } from "../../common/layout/Layout.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const SAPContracts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableName = "SAPContracts";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    contractNumber: "",
    contractingParty: "",
    authorizedPersonEmployeeId: "",
    isActive: true,
    fromSap: true,
  });

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("ContractNumber"),
      accessor: "contractNumber",
    },
    {
      Header: t("ContractingParty"),
      accessor: "contractingParty",
    },
    {
      Header: t("ValidityDateFrom"),
      accessor: "validityPeriodDateFrom",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.validityPeriodDateFrom
              ? format(
                  new Date(row.original.validityPeriodDateFrom),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("ValidityDateTo"),
      accessor: "validityPeriodDateTo",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.validityPeriodDateTo
              ? format(
                  new Date(row.original.validityPeriodDateTo),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("WarnityDateFrom"),
      accessor: "warnityPeriodDateFrom",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.warnityPeriodDateFrom
              ? format(
                  new Date(row.original.warnityPeriodDateFrom),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("WarnityDateTo"),
      accessor: "warnityPeriodDateTo",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.warnityPeriodDateTo
              ? format(new Date(row.original.warnityPeriodDateTo), "dd.MM.yyyy")
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("Active"),
      accessor: "isValid",
      Cell: ({ row }) => {
        return row.original.isValid ? t("Yes") : t("No");
      },
    },
    {
      accessor: "preview",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-primary table-btn"
              title={t("PreviewContract")}
              onClick={() => {
                previewContract(row.original.id);
              }}
            >
              <FontAwesomeIcon icon={solid("search")} />
            </button>
          </>
        );
      },
    },
  ];

  var client = new Client();

  useEffect(() => {
    reset && setReset(false);
  }, [data]);

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("SAP_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilter(parsedObject);
    }

    var response = await client.SearchSAPContracts({
      filter: storedObject ? parsedObject : filter,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    sessionStorage.setItem("SAP_FILTER", JSON.stringify(filter));
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setData(result);
  };

  const resetFilter = async () => {
    setReset(true);
    sessionStorage.removeItem("SAP_FILTER");
    let data = {
      contractNumber: "",
      contractingParty: "",
      authorizedPersonEmployeeId: "",
      isActive: true,
      fromSap: true,
    };
    setFilter(data);
    var response = await client.SearchSAPContracts({
      filter: data,
    });
    setData({ dataList: response.results, count: response.totalCount });
  };

  const previewContract = async (id) => {
    navigate("/Contracts/PreviewContract/" + id);
  };

  return (
    <MainContainer>
      <SearchSapContract
        filter={filter}
        setFilter={setFilter}
        searchData={searchData}
        resetFilter={resetFilter}
        onlyActive={false}
      />
      <br />
      <Table
        columns={columns}
        data={data}
        tableName={tableName}
        rememberFilter={rememberFilter}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setData}
        hiddenColumn={"id"}
        resetFilters={reset}
      />
    </MainContainer>
  );
};

export default SAPContracts;

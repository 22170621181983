const allRoles = [
  "Administrator",
  "Superadministrator",
  "BzrOfficer",
  "AuthorizedPersonForSecondLevelObjects",
  "AuthorizedPersonForThirdLevelObjects",
  "ApplicantExternalExecutors",
  "ApplicantOutOfWorkingTimeLicence",
  "ApplicantEmployee",
  "ApplicantRetroactiveIssuanceOfLicenses",
  "DefenceAndSecurityOfficer",
  "AdministratorOfExternalExecutorAccounts",
  "ContractAdministrator",
  "CompanyAdministrator",
];

export const getRolesForLocation = (location) => {
  var listOfRoles = ["Superadministrator"];
  if (location.includes("BZRService")) return bzrSwitch(location, listOfRoles);
  else if (location.includes("Contracts")) return contractsSwitch(location, listOfRoles);
  else if (location.includes("ExternalExecutorLicences")) return externalExecutorSwitch(location, listOfRoles);
  else if (location.includes("EmployeeLicences"))
    return employeeSwitch(location, listOfRoles);
  else if (location.includes("AdminPanel")) return adminPanelSwitch(location, listOfRoles);
  else return allRoles;
};

const bzrSwitch = (location, listOfRoles) => {
  listOfRoles.push("BzrOfficer");
  switch (location) {
    default:
      return listOfRoles;
  }
};

const contractsSwitch = (location, listOfRoles) => {
  listOfRoles.push("BzrOfficer", "AuthorizedPersonForSecondLevelObjects", "AuthorizedPersonForThirdLevelObjects", "ApplicantExternalExecutors", "ApplicantRetroactiveIssuanceOfLicenses");
  switch (location) {
    case "/Contracts/CreateContract": {
      listOfRoles.push("ContractAdministrator");
      return listOfRoles;
    }
    default:
      return listOfRoles;
  }
};

const externalExecutorSwitch = (location, listOfRoles) => {
  switch (location) {
    case "/ExternalExecutorLicences": {
      listOfRoles.push("ApplicantExternalExecutors", "AuthorizedPersonForSecondLevelObjects", "AuthorizedPersonForThirdLevelObjects", "ApplicantRetroactiveIssuanceOfLicenses", "DefenceAndSecurityOfficer");
      return listOfRoles;
    }
    case "/ExternalExecutorLicences/CreateLicence/true": {
      listOfRoles.push("ApplicantExternalExecutors");
      return listOfRoles;
    }
    case "/ExternalExecutorLicences/CreateLicence/false": {
      listOfRoles.push("ApplicantExternalExecutors");
      return listOfRoles;
    }
    case "/ExternalExecutorLicences/CreateLicenceRenewal": {
      listOfRoles.push("ApplicantExternalExecutors");
      return listOfRoles;
    }
    case "/ExternalExecutorLicences/CreateLicenceRetroactive": {
      listOfRoles.push("ApplicantRetroactiveIssuanceOfLicenses");
      return listOfRoles;
    }
    case "/ExternalExecutorLicences/ProcessLicence": {
      listOfRoles.push("ApplicantExternalExecutors", "AuthorizedPersonForSecondLevelObjects", "AuthorizedPersonForThirdLevelObjects", "DefenceAndSecurityOfficer");
      return listOfRoles;
    }
    default:
      if (location.includes("/ExternalExecutorLicences/PreviewLicence/") || location.includes("/ExternalExecutorLicences/GeneralPreview/")) {
        listOfRoles.push("ApplicantExternalExecutors", "AuthorizedPersonForSecondLevelObjects", "AuthorizedPersonForThirdLevelObjects", "ApplicantRetroactiveIssuanceOfLicenses", "DefenceAndSecurityOfficer");
      }
      return listOfRoles;
  }
};

const employeeSwitch = (location, listOfRoles) => {
  listOfRoles.push("ApplicantOutOfWorkingTimeLicence");
  switch (location) {
    case "/EmployeeLicences/MyLicences": {
      listOfRoles.push("ApplicantEmployee");
      return listOfRoles;
    }
    case "/EmployeeLicences/MyLicences/CreateLicenceForYourself": {
      listOfRoles.push("ApplicantEmployee");
      return listOfRoles;
    }
    default:
      if (location.includes("/EmployeeLicences/MyLicences/PreviewLicenceForYourself")) {
        listOfRoles.push("ApplicantEmployee");
      }
      return listOfRoles;
  }
};

const adminPanelSwitch = (location, listOfRoles) => {
  listOfRoles.push("Administrator");
  switch (location) {
    case "/AdminPanel/ExternalUsers": {
      listOfRoles.push("AdministratorOfExternalExecutorAccounts");
      return listOfRoles;
    }
    case "/AdminPanel/Companies": {
      listOfRoles.push("CompanyAdministrator");
      return listOfRoles;
    }
    default:
      if (location.includes("/AdminPanel/CreateUser")) {
        listOfRoles.push("AdministratorOfExternalExecutorAccounts");
      }
      return listOfRoles;
  }
};

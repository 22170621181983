import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputPersonModel } from "../../../models/InputModels";
import SAPContract from "../../../components/bzr-service/sap-contract/sapContract.component";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Client from "../../../api-services/bzr-service/client";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";
import { style } from "../../../common/select-style/SelectStyle";
import { format } from "date-fns";

const CreateInputPerson = ({
  withContract,
  addPerson,
  personData,
  isEdit,
  inputPerson,
  errors,
  setErrors,
  personIdError,
  setPersonIdError,
  passwordNumberError,
  setPasswordNumberError,
}) => {
  const { t } = useTranslation();
  const [person, setPerson] = useState(new InputPersonModel());
  const [searchPerson, setSearchPerson] = useState(new InputPersonModel());
  const [edit, setEdit] = useState(false);
  const [sapError, setSapError] = useState(false);
  const [activeNextYear, setActiveNextYear] = useState(false);
  const statuses = [
    {
      value: 1,
      label: "Aktivan",
    },
    {
      value: 4,
      label: "Suspendovan",
    },
  ];
  const divClass = inputPerson ? "form-group" : "form-group col-md-4";

  const schema = {
    companyName: inputPerson ? Joi.optional() : Joi.string().required(),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    employeePosition: Joi.optional(),
    identityCardNumber: Joi.string().length(9).allow(""),
    identityCardPlace: Joi.optional(),
    identityPassportNumber: Joi.optional(),
    identityPassportPlace: Joi.optional(),
  };

  var client = new Client();

  useEffect(() => {
    if (personData) {
      setPerson(personData);
      setEdit(isEdit);
      let nextYear =
        personData.fromDaughterCompany &&
        personData.inputForNextYear &&
        new Date() < new Date(personData.validFrom) &&
        new Date() < new Date(personData.validTo);
      setActiveNextYear(nextYear);
    }
  }, [personData]);

  const handleChange = (contract) => {
    setPerson({
      ...person,
      fkSapcontract: contract.id,
      companyName: contract.contractingParty,
    });
    setSapError(false);
    setErrors({
      ...errors,
      companyName: null,
    });
  };

  const handlePersonChange = (value) => {
    if (value) {
      var obj = value;
    } else {
      var obj = new InputPersonModel();
    }
    obj.fkSapcontract = person.fkSapcontract;
    obj.fkSapcontractNavigation = person.fkSapcontractNavigation;
    obj.companyName = person.companyName;
    setPerson(obj);
    setSearchPerson(obj);
  };

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, person, errors, schema, t);
    setPerson(result.data);
    setErrors(result.errors);
  };

  const getCreatedPersons = async (value) => {
    if (value.length > 2) {
      let data = {
        filter: { firsNameOrLastNameOrIdentityNumber: value },
      };
      let response = await client.SearchPersons(data, true);
      return response.results;
    }
  };

  const handleAdding = () => {
    if (person.foreigner) {
      schema.identityPassportNumber = Joi.string().required();
      schema.identityPassportPlace = Joi.string().required();
      schema.identityCardNumber = Joi.optional();
      schema.identityCardPlace = Joi.optional();
    } else {
      schema.identityCardNumber = Joi.string().length(9).required();
      schema.identityCardPlace = Joi.string().required();
      schema.identityPassportNumber = Joi.optional();
      schema.identityPassportPlace = Joi.optional();
    }

    const errorsResult = validateForm(person, schema, t);
    setErrors(errorsResult);

    let contractError =
      !inputPerson && withContract && !person.fkSapcontract ? true : false;
    setSapError(contractError);

    if (!errorsResult && !contractError) {
      addPerson(person);
    } else if (
      (errorsResult?.identityCardNumber && personIdError) ||
      (errorsResult?.identityPassportNumber && passwordNumberError)
    ) {
      setPersonIdError(false);
      setPasswordNumberError(false);
    }
  };

  return (
    <>
      {!inputPerson && (
        <>
          {withContract && (
            <>
              <SAPContract
                fkSapcontract={person.fkSapcontract}
                handleChange={handleChange}
                canChange={true}
                contractData={person.fkSapcontractNavigation}
              />
              {sapError && (
                <div className="text-danger">{t("RequiredField")}</div>
              )}
            </>
          )}
          <div className={divClass}>
            <label>{t("Executor")}</label>
            <input
              type="text"
              className="form-control"
              value={person.companyName}
              onChange={(e) =>
                handlePropertyChange("companyName", e.target.value)
              }
            />
            {errors?.companyName && (
              <div className="text-danger">{errors.companyName}</div>
            )}
          </div>
          {isEdit && (
            <div className={divClass}>
              <label>{t("Status")}</label>
              {person.status == 2 ? (
                <>
                  <input
                    type="text"
                    className="form-control"
                    value={"Neaktivan"}
                    disabled
                  />
                  {activeNextYear && (
                    <small>
                      Lice je neaktivno do{" "}
                      {format(new Date(person.validFrom), "dd.MM.yyyy.")}
                    </small>
                  )}
                </>
              ) : (
                <Select
                  placeholder={t("SelectFromList")}
                  options={statuses}
                  onChange={(e) => {
                    setPerson({ ...person, status: e.value });
                  }}
                  menuPortalTarget={document.body}
                  styles={style}
                  value={statuses.filter((x) => x.value == person.status)}
                />
              )}
            </div>
          )}
        </>
      )}

      {!isEdit && (
        <div className={divClass}>
          <label>Dodavanje već kreiranog lica</label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            styles={style}
            value={searchPerson}
            getOptionLabel={(e) =>
              e.id &&
              "(" +
                (e.foreigner
                  ? e.identityPassportNumber
                  : e.identityCardNumber) +
                ") " +
                e.firstName +
                " " +
                e.lastName
            }
            getOptionValue={(e) => e.id}
            loadOptions={getCreatedPersons}
            onChange={handlePersonChange}
            placeholder="Potrebno je uneti minimum 3 karaktera..."
            isClearable
          />
        </div>
      )}
      {!inputPerson && withContract && (
        <div className={divClass}>
          <label>{t("WithBZRContract")} &nbsp;</label>
          <input
            type="checkbox"
            value={person.bzrWithContract}
            checked={person.bzrWithContract}
            onChange={() =>
              setPerson({
                ...person,
                bzrWithContract: !person.bzrWithContract,
              })
            }
          />
        </div>
      )}
      <div className={divClass}>
        <label>{t("Name")}</label>
        <input
          type="text"
          className="form-control"
          value={person.firstName}
          onChange={(e) => handlePropertyChange("firstName", e.target.value)}
        />
        {errors?.firstName && (
          <div className="text-danger">{errors.firstName}</div>
        )}
      </div>
      <div className={divClass}>
        <label>{t("LastName")}</label>
        <input
          type="text"
          className="form-control"
          value={person.lastName}
          onChange={(e) => handlePropertyChange("lastName", e.target.value)}
        />
        {errors?.lastName && (
          <div className="text-danger">{errors.lastName}</div>
        )}
      </div>
      {person.bzrWithContract && (
        <div className={divClass}>
          <label>{t("Workplace")}</label>
          <input
            type="text"
            className="form-control"
            value={person.employeePosition}
            onChange={(e) =>
              handlePropertyChange("employeePosition", e.target.value)
            }
          />
          {errors?.employeePosition && (
            <div className="text-danger">{errors.employeePosition}</div>
          )}
        </div>
      )}
      <div className={divClass}>
        <label>{t("Foreigner")} &nbsp;</label>
        <input
          type="checkbox"
          value={person.foreigner}
          checked={person.foreigner}
          onChange={() =>
            setPerson({
              ...person,
              foreigner: !person.foreigner,
              identityPassportNumber: "",
              identityPassportPlace: "",
              identityCardNumber: "",
              identityCardPlace: "",
            })
          }
        />
      </div>
      {person.foreigner ? (
        <>
          <div className={divClass}>
            <label>{t("PassportNumber")}</label>
            <input
              type="text"
              className="form-control"
              value={person.identityPassportNumber}
              onChange={(e) => {
                handlePropertyChange("identityPassportNumber", e.target.value);
                inputPerson && setPasswordNumberError(false);
              }}
            />
            {(errors?.identityPassportNumber || passwordNumberError) && (
              <div className="text-danger">
                {passwordNumberError
                  ? t("passwordNumberNotUnique")
                  : errors.identityPassportNumber}
              </div>
            )}
          </div>
          <div className={divClass}>
            <label>{t("PassportPlace")}</label>
            <input
              type="text"
              className="form-control"
              value={person.identityPassportPlace}
              onChange={(e) =>
                handlePropertyChange("identityPassportPlace", e.target.value)
              }
            />
            {errors?.identityPassportPlace && (
              <div className="text-danger">{errors.identityPassportPlace}</div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={divClass}>
            <label>{t("IdentityCardNumber")}</label>
            <input
              type="text"
              className="form-control"
              value={person.identityCardNumber}
              onChange={(e) => {
                handlePropertyChange("identityCardNumber", e.target.value);
                inputPerson && setPersonIdError(false);
              }}
            />
            {(errors?.identityCardNumber || personIdError) && (
              <div className="text-danger">
                {personIdError ? t("personIdError") : errors.identityCardNumber}
              </div>
            )}
          </div>
          <div className={divClass}>
            <label>{t("IdentityCardPlace")}</label>
            <input
              type="text"
              className="form-control"
              value={person.identityCardPlace}
              onChange={(e) =>
                handlePropertyChange("identityCardPlace", e.target.value)
              }
            />
            {errors?.identityCardPlace && (
              <div className="text-danger">{errors.identityCardPlace}</div>
            )}
          </div>
        </>
      )}
      <div className="clearfix">
        <button type="button" className="btn btn-accept" onClick={handleAdding}>
          {edit ? t("Change") : t("Add")}
        </button>
      </div>
    </>
  );
};

export default CreateInputPerson;

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { MainContainer } from "../../common/layout/Layout.style";
import Joi from "joi-browser";
import Select from "react-select";
import { style } from "../../common/select-style/SelectStyle";
import { handleSave, validateForm } from "../../common/validation/validation";
import { Contract } from "../../models/ContractModel";
import CustomDatePicker from "../../common/custom-date-picker/CustomDatePicker.component";
import Company from "../../components/admin-panel/company/company.component";
import Client from "../../api-services/sap-contract/client";
import ButtonWithConfirmation from "../../common/button-with-confirmation/ButtonWithConfirmation.component";
import RenderOnRole from "../../common/route-and-role/RenderOnRole";
import useUserRoles from "../../hooks/useUserRoles";

const CreateContract = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { userRoles, setUserRoles } = useUserRoles();
  const hasRole = userRoles.includes("Superadministrator") || userRoles.includes("ContractAdministrator");

  const [contract, setContract] = useState(new Contract());
  const [edit, setEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showActivate, setShowActivate] = useState(false);
  const [errors, setErrors] = useState({});
  const [companyError, setCompanyError] = useState(false);

  var client = new Client();

  const contractTypes = [
    {
      value: 1,
      label: "Ugovor sa rokom važenja",
    },
    {
      value: 2,
      label: "Ugovor bez roka važenja",
    },
  ];

  const schema = {
    contractType: Joi.required(),
    contractNumber: Joi.string().required(),
    contractingParty: Joi.string().required(),
    companyIdentifier: Joi.string(),
    vendorContractNumber: Joi.string().required(),
    validityPeriodDateFrom: Joi.date().required(),
    validityPeriodDateTo: Joi.date().required(),
    warnityPeriodDateFrom: Joi.optional(),
    warnityPeriodDateTo: Joi.optional(),
  };

  useEffect(() => {
    if (id) getContract(id);
    else setDisabled(false);
  }, [id]);

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, contract, errors, schema, t);
    setContract(result.data);
    setErrors(result.errors);
  };

  const getContract = async (id) => {
    let response = await client.GetContract(id);
    setContract(response);
    setDisabled(!response.canChangeContract || !hasRole);
    setEdit(true);
    setShowActivate(response.contractType == 2);
  };

  const createContract = async () => {
    if (contract.contractType == 1) {
      schema.validityPeriodDateTo = Joi.date().required();
    } else {
      schema.validityPeriodDateTo = Joi.optional();
      contract.validityPeriodDateTo = null;
      contract.warnityPeriodDateFrom = null;
      contract.warnityPeriodDateTo = null;
    }

    let errorsResult = validateForm(contract, schema, t);
    setErrors(errorsResult);

    let companyErrorResult = !contract.companyIdentifier;
    setCompanyError(companyErrorResult);

    if (!errorsResult && !companyErrorResult) {
      if (edit) var response = await client.UpdateContract(contract);
      else var response = await client.CreateContract(contract);

      if (response != "Error") navigate("/Contracts");
    }
  };

  const changeStatus = async (status) => {
    let response = await client.ChangeStatus(contract.id, status);
    setContract({
      ...contract,
      unlimitedValidity: !contract.unlimitedValidity,
    });
  };

  const handleCompanyChange = (companyData) => {
    setContract({
      ...contract,
      companyIdentifier: companyData.companyIdentifier,
      contractingParty: companyData.companyName,
      address: companyData.address,
      email: companyData.email,
    });
    setCompanyError(false);
  };

  return (
    <MainContainer>
      <h5>{edit ? t("ContractEdit") : t("ContractCreate")}</h5>
      {edit && showActivate && (
        <RenderOnRole roles={["ContractAdministrator"]}>
          <div style={{ marginBottom: "10px" }}>
            <ButtonWithConfirmation
              className={
                "btn " +
                (contract.unlimitedValidity ? "btn-danger" : "btn-accept")
              }
              onClick={() => changeStatus(!contract.unlimitedValidity)}
              contentText={t("DeActivateContractWarning")}
              handleYesText={t("Accept")}
            >
              {contract.unlimitedValidity ? t("Deactivate") : t("Activate")}
            </ButtonWithConfirmation>
          </div>
        </RenderOnRole>
      )}
      <Company
        user={null}
        handleChange={handleCompanyChange}
        edit={edit}
        companyId={contract.companyIdentifier}
        canChange={contract.canChangeContract && hasRole}
      />
      {companyError && <div className="text-danger">{t("RequiredField")}</div>}
      <div className="form-group col-md-4">
        <label>{t("ContractType")}</label>
        <Select
          placeholder={t("SelectFromList")}
          options={contractTypes}
          onChange={(e) => {
            handlePropertyChange("contractType", e.value);
          }}
          menuPortalTarget={document.body}
          styles={style}
          value={contractTypes.filter((x) => x.value == contract.contractType)}
          isDisabled={disabled}
        />
        {errors?.contractType && (
          <div className="text-danger">{errors.contractType}</div>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>{t("ContractNumber")}</label>
        <input
          type="text"
          className="form-control"
          value={contract.contractNumber}
          onChange={(e) =>
            handlePropertyChange("contractNumber", e.target.value)
          }
          disabled={disabled}
        />
        {errors?.contractNumber && (
          <div className="text-danger">{errors.contractNumber}</div>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>{t("ContractingParty")}</label>
        <input
          type="text"
          className="form-control"
          value={contract.contractingParty}
          disabled
        />
      </div>
      <div className="form-group col-md-4">
        <label>{t("VendorContractNumber")}</label>
        <input
          type="text"
          className="form-control"
          value={contract.vendorContractNumber}
          onChange={(e) =>
            handlePropertyChange("vendorContractNumber", e.target.value)
          }
          disabled={disabled}
        />
        {errors?.vendorContractNumber && (
          <div className="text-danger">{errors.vendorContractNumber}</div>
        )}
      </div>
      <div className="row col-md-10">
        <div className="form-group col-md-2">
          <label>{t("ValidityDateFrom")}</label>
          <br />
          <CustomDatePicker
            selected={contract.validityPeriodDateFrom}
            onChange={(date) =>
              handlePropertyChange("validityPeriodDateFrom", date)
            }
            disabled={!hasRole}
          />
          {errors?.validityPeriodDateFrom && (
            <div className="text-danger">{errors.validityPeriodDateFrom}</div>
          )}
        </div>
        {contract.contractType == 1 && (
          <>
            <div className="form-group col-md-2">
              <label>{t("ValidityDateTo")}</label>
              <br />
              <CustomDatePicker
                selected={contract.validityPeriodDateTo}
                onChange={(date) =>
                  handlePropertyChange("validityPeriodDateTo", date)
                }
                disabled={!hasRole}
              />
              {errors?.validityPeriodDateTo && (
                <div className="text-danger">{errors.validityPeriodDateTo}</div>
              )}
            </div>
            <div className="form-group col-md-2">
              <label>{t("WarnityDateFrom")}</label>
              <br />
              <CustomDatePicker
                selected={contract.warnityPeriodDateFrom}
                onChange={(date) =>
                  handlePropertyChange("warnityPeriodDateFrom", date)
                }
                disabled={!hasRole}
                isClearable={hasRole}
              />
              {errors?.warnityPeriodDateFrom && (
                <div className="text-danger">
                  {errors.warnityPeriodDateFrom}
                </div>
              )}
            </div>
            <div className="form-group col-md-2">
              <label>{t("WarnityDateTo")}</label>
              <br />
              <CustomDatePicker
                selected={contract.warnityPeriodDateTo}
                onChange={(date) =>
                  handlePropertyChange("warnityPeriodDateTo", date)
                }
                disabled={!hasRole}
                isClearable={hasRole}
              />
              {errors?.warnityPeriodDateTo && (
                <div className="text-danger">{errors.warnityPeriodDateTo}</div>
              )}
            </div>
          </>
        )}
      </div>
      <RenderOnRole roles={["ContractAdministrator"]}>
        <button
          type="button"
          className="btn btn-accept"
          onClick={createContract}
        >
          {edit ? t("Change") : t("Create")}
        </button>
      </RenderOnRole>
    </MainContainer>
  );
};

export default CreateContract;

import { Error, Success } from "../toastify/toastify";
import { toast } from "react-toastify";

const HandlingResponse = (response) => {
  var messageCode = "200";

  switch (true) {
    //Success
    case response.status == 200: {
      Success(messageCode);
      return "Success";
    }
    //Error
    case response.ErrorCode == 400: {
      messageCode = "400";
      Error(messageCode);
      return "Error";
    }
    //Common errors
    case response.ErrorCode == 0: {
      if (response.ErrorCode) messageCode = response.ErrorCode;
      else messageCode = "400";

      Error(messageCode);
      return "Error";
    }
    //Validation
    case response.ErrorCode == 2: {
      return { code: response.ErrorCode, data: response.ErrorData };
    }
    case response.ErrorCode == 2003 || response.ErrorCode == 2004 || response.ErrorCode == 2005: {
      return { code: response.ErrorCode, data: response.ErrorData };
    }
    //Errors
    case response.ErrorCode > 2: {
      if (response.ErrorCode) messageCode = response.ErrorCode;
      else messageCode = "400";

      Error(messageCode);
      return "Error";
    }
    //Authorization
    case !response.ErrorCode && response.status == "403": {
      messageCode = "403";
      Error(messageCode);
      return "Error";
    }
    default: {
      //Error("0");
      toast.error(JSON.stringify(response), {
        hideProgressBar: true,
        position: "top-center",
        autoClose: 150000,
        zIndex: 100000,
      });
      break;
    }
  }
};

export default HandlingResponse;

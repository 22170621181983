import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table/Table.component";
import DialogModal from "../../../common/modal/Modal.component";
import { format } from "date-fns";
import Client from "../../../api-services/sap-contract/client";
import SearchSapContract from "../../sap-contract/search-sap-contract/searchSapContract.component";

const SAPContract = ({
  fkSapcontract,
  handleChange,
  canChange,
  contractData,
  contractNumber,
}) => {
  const { t } = useTranslation();
  const tableName = "SAP_Contracts";

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    contractNumber: "",
    contractingParty: "",
    authorizedPersonEmployeeId: "",
    isActive: true,
  });
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState([]);

  const [sapContracts, setSapContracts] = useState([]);
  const [chosenContract, setChosenContract] = useState(fkSapcontract);
  const [show, setShow] = useState(false);

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("ContractNumber"),
      accessor: "contractNumber",
    },
    {
      Header: t("ContractingParty"),
      accessor: "contractingParty",
    },
    {
      Header: t("AuthorizedPerson"),
      accessor: "authorizedPersons",
      Cell: ({ value }) => {
        return (
          <div>
            {value.map((x, i) => {
              return x.firstAndLastName + (value.length != i + 1 ? ", " : "");
            })}
          </div>
        );
      },
    },
    {
      Header: t("ValidityDateFrom"),
      accessor: "validityPeriodDateFrom",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.validityPeriodDateFrom
              ? format(
                  new Date(row.original.validityPeriodDateFrom),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("ValidityDateTo"),
      accessor: "validityPeriodDateTo",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.validityPeriodDateTo
              ? format(
                  new Date(row.original.validityPeriodDateTo),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("WarnityDateFrom"),
      accessor: "warnityPeriodDateFrom",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.warnityPeriodDateFrom
              ? format(
                  new Date(row.original.warnityPeriodDateFrom),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("WarnityDateTo"),
      accessor: "warnityPeriodDateTo",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.warnityPeriodDateTo
              ? format(new Date(row.original.warnityPeriodDateTo), "dd.MM.yyyy")
              : ""}
          </div>
        );
      },
    },
  ];

  var client = new Client();

  useEffect(() => {
    if (contractData) {
      setData([contractData]);
    }
  }, [contractData]);

  useEffect(() => {
    setFilter({ ...filter, contractNumber: contractNumber });
  }, [contractNumber]);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const chooseSAPContract = () => {
    let chosen = sapContracts.dataList.filter((x) => x.id === chosenContract);
    setData(chosen);
    handleChange(chosen[0]);
    HideDialog();
  };

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    // const storedObject = sessionStorage.getItem("SAP_FILTER");
    // if (storedObject) {
    //   var parsedObject = JSON.parse(storedObject);
    //   setFilter(parsedObject);
    // }

    var response = await client.SearchSAPContracts({
      filter: filter,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    //sessionStorage.setItem("SAP_FILTER", JSON.stringify(filter));
    const result = await fetchDataForPage(pageNumber, pageSize);
    setSapContracts(result);
  };

  const resetFilter = async () => {
    let data = {
      contractNumber: "",
      contractingParty: "",
      authorizedPersonEmployeeId: "",
      isActive: true,
    };
    setFilter(data);
    //sessionStorage.removeItem("SAP_FILTER");
    var response = await client.SearchSAPContracts({
      filter: data,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });
    setSapContracts({ dataList: response.results, count: response.totalCount });
  };

  const handleTableRowClick = useCallback((response) => {
    setChosenContract(response.id);
  });

  return (
    <>
      {data.length > 0 && (
        <>
          <h5>{t("SAPContractInfo")}</h5>
          <Table
            columns={columns}
            data={data}
            tableName={tableName}
            rememberFilter={false}
            hiddenColumn={"id"}
          />
        </>
      )}
      {canChange && (
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: "10px" }}
          onClick={ShowDialog}
        >
          {t("ChooseSAPContract")}
        </button>
      )}
      <DialogModal show={show} onHide={HideDialog} title={t("SAPContract")}>
        <>
          <SearchSapContract
            filter={filter}
            setFilter={setFilter}
            searchData={searchData}
            resetFilter={resetFilter}
            onlyActive={true}
          />
          <Table
            columns={columns}
            data={sapContracts}
            tableName={tableName}
            rememberFilter={true}
            fetchFunction={fetchDataForPage}
            serverSide={true}
            setData={setSapContracts}
            hiddenColumn={"id"}
            handleClickOnRow={handleTableRowClick}
          />
          <button
            type="button"
            className="btn btn-accept pull-right"
            onClick={chooseSAPContract}
          >
            {t("Choose")}
          </button>
        </>
      </DialogModal>
    </>
  );
};

export default SAPContract;

import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table/Table.component";
import DialogModal from "../../../common/modal/Modal.component";
import Client from "../../../api-services/bzr-service/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PersonEquipmentStatus from "../../../common/person-equipment-status/PersonEquipmentStatus";

const SearchEquipments = ({
  sapContract,
  edit,
  equipmentsData,
  setEquipmentsData,
  withContract,
  licenceRenewal,
  licenceCase,
}) => {
  const { t } = useTranslation();
  const tableName = "BZR_Equipments";

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    name: "",
    model: "",
    identificationNumber: "",
    withSapContract: withContract,
    status: 1,
    // fkSapcontract: 0,
  });
  const [bzrEquipments, setBzrEquipments] = useState([]);
  const [chosenEquipments, setChosenEquipments] = useState([]);
  const [chosenEquipmentsIds, setChosenEquipmentsIds] = useState([]);
  const [show, setShow] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState([]);

  const columns = [
    {
      disableSortBy: true,
      Header: () => {
        return (
          <input
            type="checkbox"
            value={chosenEquipmentsIds.length == bzrEquipments.dataList?.length}
            checked={
              chosenEquipmentsIds.length == bzrEquipments.dataList?.length
            }
            onChange={(e) => selectAllEquipments(e.target.checked)}
          />
        );
      },
      accessor: "isChecked",
      Cell: ({ row }) => {
        return (
          <input
            type="checkbox"
            value={chosenEquipmentsIds.includes(row.original.id)}
            checked={chosenEquipmentsIds.includes(row.original.id)}
            onChange={(e) => chooseEquipment(e.target.checked, row.original.id)}
          />
        );
      },
    },
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Status"),
      accessor: "bzrStatus",
      Cell: ({ value }) => {
        return <PersonEquipmentStatus value={value} />;
      },
    },
    {
      Header: t("Name"),
      accessor: "name",
    },
    {
      Header: t("Model"),
      accessor: "model",
    },
    {
      Header: t("Type"),
      accessor: "type",
    },
    {
      Header: t("IdentificationNumber"),
      accessor: "identificationNumber",
    },
    {
      Header: t("CompanyName"),
      accessor: "companyName",
    },
    {
      accessor: "remove",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-danger table-btn"
              title={t("DeleteEquipment")}
              onClick={() => {
                removeEquipment(row.original.id);
              }}
            >
              <FontAwesomeIcon icon={solid("remove")} />
            </button>
          </>
        );
      },
    },
  ];

  var client = new Client();

  useEffect(() => {
    let newFilter = {
      ...filter,
      fkSapcontract: sapContract,
      withSapContract: withContract,
    };
    if (licenceRenewal) {
      newFilter.isLicenceExtension = true;
      newFilter.caseId = licenceCase;
    }
    setFilter(newFilter);
    setData([]);
    setChosenEquipments([]);
    setChosenEquipmentsIds([]);
    if (edit) {
      setEquipmentsData([]);
    }
  }, [sapContract, licenceCase]);

  useEffect(() => {
    if (equipmentsData) {
      setData(equipmentsData);
    }
  }, [equipmentsData]);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const chooseEquipments = () => {
    setData(chosenEquipments);
    setEquipmentsData(chosenEquipments);
    HideDialog();
  };

  const selectAllEquipments = (value) => {
    if (value) {
      let equipments = bzrEquipments.dataList;
      let ids = equipments.map((x) => {
        return x.id;
      });
      setChosenEquipmentsIds(ids);
      setChosenEquipments(equipments);
    } else {
      setChosenEquipmentsIds([]);
      setChosenEquipments([]);
    }
  };

  const removeEquipment = (id) => {
    let dataCopy = data.filter((x) => x.id !== id);
    let equipmentsIds = chosenEquipmentsIds.filter((x) => x !== id);
    setData(dataCopy);
    setEquipmentsData(dataCopy);
    setChosenEquipments(dataCopy);
    setChosenEquipmentsIds(equipmentsIds);
  };

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    var response = await client.SearchEquipments({
      filter: filter,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    const result = await fetchDataForPage(pageNumber, pageSize);
    setBzrEquipments(result);
  };

  const resetFilter = async () => {
    let data = {
      name: "",
      model: "",
      identificationNumber: "",
      status: 1,
      withSapContract: withContract,
      fkSapcontract: sapContract,
    };
    if (licenceRenewal) {
      data.isLicenceExtension = true;
      data.caseId = licenceCase;
    }
    setFilter(data);
    var response = await client.SearchEquipments({
      filter: data,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });
    setBzrEquipments({
      dataList: response.results,
      count: response.totalCount,
    });
  };

  const handleTableRowClick = useCallback((response) => {
    setChosenEquipments([...chosenEquipments, response.id]);
  });

  const chooseEquipment = (checked, id) => {
    if (checked) {
      if (!chosenEquipmentsIds.includes(id)) {
        setChosenEquipmentsIds([...chosenEquipmentsIds, id]);
        let chosen = bzrEquipments.dataList.filter((x) => x.id == id)[0];
        setChosenEquipments([...chosenEquipments, chosen]);
      }
    } else {
      let data = chosenEquipmentsIds.filter((x) => x != id);
      setChosenEquipmentsIds(data);
      let chosen = chosenEquipments.filter((x) => x.id != id);
      setChosenEquipments(chosen);
    }
  };

  return (
    <div className="clearfix">
      {data.length > 0 && (
        <>
          <h5>{t("Equipments")}</h5>
          <Table
            columns={columns}
            data={data}
            tableName={tableName}
            rememberFilter={false}
            hiddenColumn={["id", "isChecked", edit ? "bzrStatus" : "remove"]}
          />
        </>
      )}
      {edit && (
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: "10px" }}
          onClick={ShowDialog}
        >
          {t("AddInputEquipment")}
        </button>
      )}
      <DialogModal show={show} onHide={HideDialog} title={"BZR oprema"}>
        <form>
          <div className="row">
            <div className="form-group col-md-6">
              <label>{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                value={filter.name}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group col-md-6">
              <label>{t("Model")}</label>
              <input
                type="text"
                className="form-control"
                value={filter.model}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    model: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group col-md-6">
              <label>{t("IdentificationNumber")}</label>
              <input
                type="text"
                className="form-control"
                value={filter.identificationNumber}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    identificationNumber: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-12 text-right">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                searchData();
              }}
            >
              {t("Search")}
            </button>
            <button
              type="button"
              className="btn btn-cancel m-2"
              onClick={resetFilter}
            >
              {t("CancelSearch")}
            </button>
          </div>
          <Table
            columns={columns}
            data={bzrEquipments}
            tableName={tableName}
            rememberFilter={true}
            fetchFunction={fetchDataForPage}
            serverSide={true}
            setData={setBzrEquipments}
            hiddenColumn={["id", "remove", edit && "bzrStatus"]}
          />
          <button
            type="button"
            className="btn btn-accept pull-right"
            onClick={chooseEquipments}
          >
            {t("Choose")}
          </button>
        </form>
      </DialogModal>
    </div>
  );
};

export default SearchEquipments;

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Licence } from "../../../../models/LicenceModel";
import Client from "../../../../api-services/licences/client";
import { serializeObjectIntoFormData } from "../../../../utils/serialize-form-data/serializeFormData";
import LicenceForm from "../../../../components/external-executor-licences/licence-form/LicenceForm.component";
import SearchPersons from "../../../../components/external-executor-licences/search-persons/searchPersons.component";
import SearchEquipments from "../../../../components/external-executor-licences/search-equipments/searchEquipments.component";
import { MainContainer } from "../../../../common/layout/Layout.style";
import { formElements } from "../../../../common/licence-form-elements/FormElements";
import MultipleFiles from "../../../../common/multiple-files/multipleFiles.component";
import CreatePersons from "../../../../components/external-executor-licences/create-persons/CreatePersons.component";
import Joi from "joi-browser";
import {
  validateForm,
  handleSave,
} from "../../../../common/validation/validation";

const CreateLicenceRenewal = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [licence, setLicence] = useState(new Licence());
  const [oldLicence, setOldLicence] = useState(new Licence());
  const [licenceType, setLicenceType] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const [personsError, setPersonsError] = useState(false);
  const [errors, setErrors] = useState({});
  const [schema, setSchema] = useState({});
  const [persons, setPersons] = useState([]);
  const [equipments, setEquipments] = useState([]);

  var client = new Client();

  useEffect(() => {
    if (state) {
      setOldLicence(state.data);
      setLicence({
        ...state.data,
        licenceWorkflowDocuments: null,
        licenceWorkflowHistories: null,
        liceneWorkflowEmployeeIdOwner: null,
        licenceWorkflowEmployeeIdProcessConcluser: null,
        fkLwLicenceTypeArchive: null,
        ledgerNumber: null,
        finished: false,
        initialLicenceInTheCase: false,
      });
      setLicenceType(state.data.licenceType.documentType);
    }
  }, [state]);

  useEffect(() => {
    formElements.forEach((x) => {
      if (x.required == "all" || x.required.includes(licenceType)) {
        if (x.elementType == "input") {
          schema[x.elementName] = Joi.string().required();
        } else if (x.elementType == "date") {
          schema[x.elementName] = Joi.date().required();
        }
      } else {
        schema[x.elementName] = Joi.optional();
      }
    });
  }, [licenceType]);

  useEffect(() => {
    if (persons.length > 0) {
      setPersonsError(false);
    }
  }, [persons]);

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, licence, errors, schema, t);
    setLicence(result.data);
    setErrors(result.errors);
  };

  const createLicenceRenewal = async () => {
    const errorsResult = validateForm(licence, schema, t);
    setErrors(errorsResult);

    let errorPersons = persons.length < 1;
    setPersonsError(errorPersons);

    if (!errorsResult && !errorPersons) {
      let objectIds = licence.licenceObjectsView.map((x) => x.id);

      let data = {
        ...licence,
        parentLicenceId: oldLicence.id,
        licenceWorkflowPeople: persons,
        licenceWorkflowEquipments: equipments,
        objectIds: objectIds,
        isLicenceExtension: true,
        caseId: oldLicence.fkLicenceCase,
      };

      var formData = new FormData();
      serializeObjectIntoFormData(formData, data);

      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i].guid)
          formData.append(
            "licenceWorkflowDocuments[" + i + "].guid",
            attachments[i].guid
          );
        else
          formData.append(
            "licenceWorkflowDocuments[" + i + "].file",
            attachments[i]
          );

        formData.append(
          "licenceWorkflowDocuments[" + i + "].name",
          attachments[i].name
        );
        formData.append("licenceWorkflowDocuments[" + i + "].documentType", 1);
      }

      var response = await client.CreateLicenceRenewal(formData);

      if (response !== "Error") {
        navigate("/ExternalExecutorLicences/ProcessLicence");
      }
    }
  };

  return (
    <MainContainer>
      <h4>Dopuna iz {oldLicence.ledgerNumber}</h4>
      <br />
      <LicenceForm
        model={licence}
        setModel={setLicence}
        mode={"renewal"}
        licenceType={licenceType}
        errors={errors}
        handleChange={handlePropertyChange}
      />
      <br />
      <div className="col-md-6">
        <label>Prilozi</label>
        <MultipleFiles
          fileList={attachments}
          setFileList={setAttachments}
          accept={".pdf"}
          name={"attachments"}
        />
      </div>
      <br />
      {licenceType == 9 ? (
        <>
          <CreatePersons
            persons={persons}
            setPersons={setPersons}
            foreigner={licence.foreigner}
            licenceRenewalPersons={oldLicence.persons}
          />
          {personsError && (
            <div className="text-danger">{t("AtLeastOnePersonError")}</div>
          )}
        </>
      ) : (
        <>
          <SearchPersons
            sapContract={licence.fkSapcontract}
            edit={true}
            personsData={persons}
            setPersonData={setPersons}
            withContract={licence.fkSapcontract ? null : false}
            foreigner={licence.foreigner}
            licenceRenewal={true}
            licenceCase={licence.fkLicenceCase}
          />
          {personsError && (
            <div className="text-danger">{t("AtLeastOnePersonError")}</div>
          )}
          <br />
          <SearchEquipments
            sapContract={licence.fkSapcontract}
            edit={true}
            equipmentsData={equipments}
            setEquipmentsData={setEquipments}
            withContract={licence.fkSapcontract ? null : false}
            licenceRenewal={true}
            licenceCase={licence.fkLicenceCase}
          />
        </>
      )}
      <br />
      <div className="col-md-12 clearfix">
        <button
          type="button"
          className="btn btn-accept pull-right"
          onClick={createLicenceRenewal}
        >
          Dopuni
        </button>
      </div>
    </MainContainer>
  );
};

export default CreateLicenceRenewal;
